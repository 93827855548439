@import "../scss/variables";

.field-check {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.3rem 0;

    &__box {
        width: 2rem;
        height: 2rem;
        border: 2px solid $color-primary;
        display: flex;
        position: relative;
        margin-right: 0.5rem;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            border: 2px solid $color-white;
            background-color: $color-white;
            transition: all 0.2s ease-in-out;
        }

        &.is-checked {
            &::before {
                background-color: $color-primary;
            }
        }
    }

    &__label {
        font-size: 1.2rem;
        // font-weight: bold;
    }
}
