@import "../../scss/variables";

.apartment-filters {
    width: 100%;
    background-color: $color-white;
    box-shadow: 0 0.3rem 1rem 0 rgba(black, 0.3);
    padding: 1rem;

    &__columns {
        display: flex;
        flex-direction: column;

        @media (min-width: $breakpoint-sm) {
            flex-direction: row;
        }
    }

    &__column {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (min-width: $breakpoint-sm) {
            flex: 0 0 50%;
            width: 50%;
        }

        &.right {
            align-items: flex-start;
            margin-top: 2rem;

            @media (min-width: $breakpoint-sm) {
                align-items: flex-end;
                margin-top: 0;
            }
        }

        &-spacer {
            height: 1rem;
        }
    }
}
