@import "../../scss/variables";

.viewer-switch {
    width: 8rem;
    height: 4rem;
    display: flex;
    cursor: pointer;
    position: relative;
    margin: 1rem;

    &::before {
        content: "";
        position: absolute;
        width: 4rem;
        height: 4rem;
        left: 0;
        background-color: $color-primary;
        box-shadow: 0 0 1rem 0 $color-primary;
        z-index: 1;
        border-radius: 2rem;
        transition: all 0.2s ease-in-out;
    }

    &::after {
        content: "";
        width: 8rem;
        height: 3.2rem;
        position: absolute;
        background-color: $color-grey-1;
        top: 0.4rem;
        border-radius: 1.6rem;
        box-shadow: 0 0 1rem 0 rgba(black, 0.3);
    }

    &__icon {
        @include ff-icon;
        width: 4rem;
        height: 4rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        transition: all 0.2s ease-in-out;
        color: $color-grey-7;

        &.left {
            color: $color-white;
        }
    }

    &.off {
        &::before {
            left: 4rem;
        }

        .viewer-switch__icon {
            &.left {
                color: $color-grey-7;
            }

            &.right {
                color: $color-white;
            }
        }
    }
}
