@import "../scss/variables";

.compare {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    &__logo {
        height: 7rem;
        left: 1rem;
        top: 1rem;
        position: absolute;
        z-index: 10;
    }

    &__title {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 9rem;
        background-color: $color-white;
        // border-bottom: 1px solid $color-secondary;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        padding: 1rem 1rem 1rem 11rem;
        font-size: 2.4rem;
        z-index: 5;
    }

    &__viewer {
        flex: 0 1 100vh * (4/3);
        z-index: 2;
        overflow: scroll;
    }

    &__sidebar {
        flex: 1 0 500px;
        height: 100%;
        // background-color: $color-white;
        z-index: 3;
        box-shadow: 0 0 1rem 0 rgba(black, 0.5);
        position: relative;
        // right: 0;

        .nav {
            background-color: rgba($color-white, 0.9);
        }
    }

    &__layout {
        width: 20rem;
        height: 25rem;
        object-fit: contain;
        margin: 0 2rem;
    }

    &__list {
        position: relative;
        padding: 9rem 2rem 2rem;
        z-index: 1;
        min-height: 100%;
        display: flex;
        align-items: center;
    }

    &__table {
        height: auto;
        border-spacing: 0;
        position: relative;
        // border-collapse: collapse;

        &::before {
            position: fixed;
            content: "";
            left: 0rem;
            width: 2rem;
            height: 100%;
            top: 0;
            background-color: $color-white;
            z-index: 30;
        }

        thead {
            z-index: 20;

            th {
                font-size: 2rem;
                color: $color-black;
                padding: 1rem 1rem;
                text-align: center;
                position: sticky;
                top: 9rem;
                background-color: $color-white;
                border-bottom: 1px solid $color-grey-2;

                &:first-child {
                    left: 2rem;
                    z-index: 20;
                }

                &:not(:last-child) {
                    border-right: 1px solid $color-grey-2;
                }

                // &:not(:first-child) {
                //     border-left: 1px solid $color-grey-1;
                // }

                // &:nth-child(2n + 1):not(:nth-child(1)) {
                //     border-left: 1px solid $color-grey-1;
                // }
            }
        }

        tbody {
            z-index: 1;

            td {
                padding: 1rem 1rem;
                text-align: center;
                font-size: 1.6rem;

                &:first-child {
                    text-align: right;
                    font-weight: bold;
                    position: sticky;
                    left: 2rem;
                    z-index: 10;
                    background-color: $color-white;
                }

                &:not(:last-child) {
                    border-right: 1px solid $color-grey-2;
                }

                // &:nth-child(2n + 1):not(:nth-child(1)) {
                //     background-color: $color-grey-1;
                // }
            }
        }
    }

    &__td-center {
        display: flex;
        justify-content: center;

        .button {
            &:first-child {
                margin-right: 2rem;
            }
        }
    }

    &__empty {
        position: relative;
        padding: 11rem 2rem 2rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-text {
            text-align: center;
            font-size: 2rem;
            padding-bottom: 3rem;
        }
    }
}
