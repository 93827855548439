@import "../../scss/variables";

$apartment-navigation-width: 9rem;

.apartment-navigation {
    display: none;
    flex-direction: row;
    position: relative;
    // top: 20rem;
    // left: 0rem;
    z-index: 3;
    box-shadow: 0 0 1rem 0 rgba(black, 0.5);

    transition: right 0.2s ease-in-out;
    border-top: 1px solid $color-grey-1;

    &.is-visible {
        display: flex;
    }

    @include landscape-mode {
        display: flex;
        position: absolute;
        top: 20%;
        right: -$apartment-navigation-width;
        flex-direction: column;
        border-top: none;
        box-shadow: 0 0 1rem 0 rgba(black, 0.5);

        &.is-visible {
            right: 0;
        }
    }

    &__button {
        flex: 1 0 60px;
        height: 8rem;
        background-color: $color-white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;

        &:last-child {
            display: none;
        }

        @media (min-width: 360px) {
            &:last-child {
                display: flex;
            }
        }

        &:hover {
            background-color: $color-highlight;
        }

        &.is-active {
            background-color: $color-primary;
            // color: $color-white;
        }

        &:not(:last-child) {
            border-right: 1px solid $color-grey-1;
        }

        &-icon {
            @include ff-icon-1;
            font-size: 4rem;
        }

        &-label {
            font-size: 0.9rem;
            text-align: center;
            font-weight: bold;
            padding: 0.5rem 0 0 0;
        }

        @include landscape-mode {
            flex: none;
            width: $apartment-navigation-width;
            height: $apartment-navigation-width;
            border-right: none;

            &:not(:last-child) {
                border-bottom: 1px solid $color-grey-1;
            }
        }
    }
}
