@import "../scss/variables";

.field-range {
    width: 14rem;
    display: flex;
    position: relative;
    align-items: flex-start;
    touch-action: none;
    user-select: none;

    &__track {
        width: 100%;
        background-color: $color-grey-3;
        height: 0.4rem;
        position: absolute;
        z-index: 1;
        top: 0.8rem;
    }

    &__selector {
        flex: 0 0 2rem;
        max-width: 2rem;
        display: flex;
        flex-direction: column;
        z-index: 4;
        position: relative;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &::before {
            content: "";
            position: absolute;
            top: -1rem;
            width: 3rem;
            height: 5rem;
        }

        &.right {
            &::before {
                left: 0;
            }
        }

        &.left {
            &::before {
                right: 0;
            }
        }

        &-circle {
            width: 2rem;
            height: 2rem;
            border-radius: 1rem;
            background-color: $color-white;
            border: 3px solid $color-primary;
        }

        &-label {
            width: 2rem;
            text-align: center;
            padding: 0.2rem 0;
            color: $color-primary;
            font-weight: bold;
        }
    }

    &__track-fill {
        flex: 1 0 1px;
        height: 0.4rem;
        background-color: $color-primary;
        z-index: 2;
        margin-top: 0.8rem;
    }
}
