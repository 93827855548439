@import "./colors";
@import "./fonts";

$breakpoint-sm: 500px;
$breakpoint-md: 750px;
$breakpoint-lg: 1000px;

$dim-breakpoint-lg: 1280px;
$dim-breakpoint-xlg: 1600px;

$nav-max-width: 500px;
$nav-width-md: 320px;

@mixin landscape-mode() {
    @media (min-width: $breakpoint-md) and (min-aspect-ratio: 7/6) {
        @content;
    }
}

@mixin not-landscape-mode() {
    @media (max-width: $breakpoint-md) {
        @content;
    }
    @media (min-width: $breakpoint-md) and (max-aspect-ratio: 7/6) {
        @content;
    }
}

@mixin portrait-mode() {
    @media (min-width: $breakpoint-md) and (max-aspect-ratio: 7/6) {
        @content;
    }
}
