@import "../scss/variables";

.apartments {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    @include landscape-mode() {
        flex-direction: row;
        justify-content: flex-end;
    }

    &__logo {
        width: 5rem;
        left: 1rem;
        top: 1rem;
        position: fixed;
        z-index: 110;
    }

    &__iframe {
        width: 100%;
        height: 100%;
        border: none;
        background-color: black;
    }

    &__absolute-elements {
        position: absolute;
        z-index: 2;
        width: 100%;
        left: 0;
        top: 8rem;
        bottom: 0;

        @include landscape-mode {
            top: 0;
        }
    }

    &__viewer {
        // flex: 0 1 100vh * (4/3);
        z-index: 1;
        width: 100%;
        padding-top: 9rem;
        // height: 100vw * (3/4);

        &-element {
            // width: 100%;
            // display: block;
            position: relative;

            @include landscape-mode() {
                display: flex;
                flex: 0 1 100vh * (4/3);
            }
        }

        &-fake-sidebar {
            display: none;

            @include landscape-mode {
                display: flex;
                flex: 1 0 360px;
                width: 360px;

                &::before {
                    content: "";
                    width: 360px;
                }
            }
        }

        @include landscape-mode() {
            padding: 0;
            flex: 0 1 100vh * (4/3);
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            flex-wrap: nowrap;
        }
    }

    &__sidebar {
        // flex: 1 0 360px;
        background-color: $color-white;
        z-index: 2;
        // box-shadow: 0 0 1rem 0 rgba(black, 0.5);
        border-top: 1px solid $color-grey-1;
        position: relative;

        &-fake-viewer {
            display: none;

            @include landscape-mode {
                display: flex;
                flex: 0 1 100vh * (4/3);
            }
        }

        @include landscape-mode() {
            flex: 1 0 360px;
            border: none;
        }
    }
}
