@import "../scss/variables";

.nav {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    background-color: $color-white;
    width: 100%;
    min-height: 100%;
    // height: 100%;
    display: flex;
    justify-content: center;

    @include landscape-mode {
        width: 320px;
        box-shadow: 0 0 1rem 0 rgba(black, 0.5);
        position: fixed;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        &::before {
            content: "";
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba($color-white, 0.7);
            z-index: 1;
        }
    }

    &__center {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        z-index: 2;

        // max-width: 50rem;
    }

    &__top {
        display: flex;
        flex-direction: column;
        padding: 2rem 0;
        align-items: center;
        width: 100%;
    }

    &__header {
        width: 100%;
        height: 6rem;
        display: flex;
        justify-content: flex-end;

        &-close {
            @include ff-icon;
            font-size: 4rem;
            padding: 0 2rem;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    &__title {
        padding: 0 2rem;
        font-size: 3rem;
        text-transform: uppercase;
        @include ff-title;
        width: 100%;

        &-small {
            font-size: 2rem;
        }

        @media (min-width: $nav-max-width) {
            max-width: $nav-max-width;
        }
    }

    &__caption {
        text-align: center;
        font-size: 3rem;
        padding: 2rem;
        width: 100%;

        @media (min-width: $nav-max-width) {
            max-width: $nav-max-width;
        }
    }

    &__middle {
        flex: 1 0 35rem;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        justify-content: center;
        width: 100%;

        @media (min-width: $nav-max-width) {
            max-width: $nav-max-width;
        }
    }

    &__link {
        width: 100%;
        background-color: $color-grey-1;
        padding: 1rem 1.5rem;
        font-size: 1.6rem;
        margin-bottom: 2rem;
        border-radius: 1.5rem;
        border: 2px solid transparent;
        text-align: center;
        text-decoration: none;
        color: $color-grey-7;
        // font-weight: bold;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 0 1rem 0 $color-grey-5;
        text-transform: uppercase;

        &:hover {
            background-color: $color-primary;
            color: $color-white;
        }

        &.active {
            border: 2px solid $color-primary;
        }
    }

    &__bottom {
        padding: 2rem;
        text-align: center;
        font-size: 1.2rem;
        color: $color-grey-6;
    }
}
