@font-face {
    font-family: "icon1";
    src: url("fonts/icon-1/icomoon.eot?jiawnr");
    src: url("fonts/icon-1/icomoon.eot?jiawnr#iefix") format("embedded-opentype"),
        url("fonts/icon-1/icomoon.ttf?jiawnr") format("truetype"),
        url("fonts/icon-1/icomoon.woff?jiawnr") format("woff"),
        url("fonts/icon-1/icomoon.svg?jiawnr#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
