@import "../scss/variables";

.apartment-card {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    &__viewer {
        flex: 0 1 100vh * (4/3);
        z-index: 1;
        position: relative;
    }

    &__iframe {
        border: none;
        width: 100%;
        height: 100%;
    }

    &__sidebar {
        flex: 1 0 500px;
        background-color: $color-white;
        z-index: 2;
        box-shadow: 0 0 1rem 0 rgba(black, 0.5);
        position: relative;
    }

    &__left {
       

        &-iframe {
            width: 100%;
            height: 100%;
            border: none;
        }

        &-buttons {
            width: 7rem;
            height: 42rem;
            position: absolute;
            right: 0;
            z-index: 30;
            top: 8rem;
            background-color: $color-white;
            box-shadow: 0 0 2rem 0 rgba(black, 0.3);
            display: flex;
            flex-direction: column;

            @media (min-width: 1400px) {
                width: 10rem;
                height: 60rem;
            }
        }

        &-button {
            width: 100%;
            height: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            color: black;

            &-icon {
                @include ff-icon;
                font-size: 3rem;

                @media (min-width: 1700px) {
                    font-size: 4rem;
                }
            }

            &-logo {
                width: 2.5rem;
                height: 2.5rem;
                position: relative;

                @media (min-width: 1700px) {
                    width: 4rem;
                    height: 4rem;
                }

                &-img {
                    position: absolute;
                    height: 100%;
                    transition: opacity 0.2s ease-in-out;

                    &.light {
                        opacity: 0;
                    }
                }
            }

            &-label {
                text-align: center;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 0.78rem;
                padding-top: 0.2rem;

                @media (min-width: 1400px) {
                    font-size: 1.1rem;
                }
            }

            &:hover {
                background-color: $color-grey-2;
            }

            &.is-active {
                background-color: $color-primary;

                .apartment-view {
                    &__left {
                        &-button {
                            &-logo {
                                &-img {
                                    &.dark {
                                        opacity: 0;
                                    }
                                    &.light {
                                        opacity: 1;
                                    }
                                }
                            }
                            &-icon {
                                color: $color-white;
                            }
                            &-label {
                                color: $color-white;
                            }
                        }
                    }
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid $color-grey-7;
            }
        }
    }
}
