@import "../../scss/variables";

.apartment-list {
    width: 100%;
    min-height: 100%;
    z-index: 2;
    background-color: $color-white;
    position: relative;
    // overflow-x: hidden;
    // overflow-y: scroll;

    @include landscape-mode() {
        box-shadow: 0 0 1rem 0 rgba(black, 0.5);
    }

    &__header {
        padding: 2rem 2rem 1rem;
        background-color: $color-white;
        position: relative;
        top: 0;
        z-index: 3;

        @include landscape-mode() {
            position: sticky;
            top: 0;
        }

        &-bar {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            height: 9rem;

            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;
            background-color: $color-white;
            padding: 2rem 2rem 2rem 7rem;
            box-shadow: 0 0 1rem 0 rgba(black, 0.5);

            @include landscape-mode() {
                position: relative;
                padding: 0;
                height: 6rem;
                box-shadow: none;
                background-color: $color-grey-1;
            }
        }

        &-title {
            padding: 1rem;
            font-size: 3rem;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: $color-black;
        }

        &-icon {
            @include ff-icon;
            display: flex;
            align-items: center;
            padding: 1rem 0;
            font-size: 4rem;
            color: $color-black;
            cursor: pointer;

            @include landscape-mode {
                padding: 1rem;
            }
        }

        &-building {
            width: 100%;
            padding: 1rem;
            font-size: 2.4rem;
            color: $color-primary;
            font-weight: bold;
        }

        &-view-mode {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-button {
                display: flex;
                align-items: center;
                color: $color-grey-7;
                cursor: pointer;
                padding: 1rem;
            }

            &-icon {
                @include ff-icon;
                padding-right: 0.5rem;
                font-size: 2.5rem;
            }

            &-label {
                font-size: 1.4rem;
                text-transform: uppercase;
            }
        }

        &-free-apartments {
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            color: $color-grey-7;
            padding-left: 1rem;
            text-transform: uppercase;

            b {
                font-size: 1.6rem;
                padding-right: 0.5rem;
            }
        }

        &::after {
            content: "";
            position: absolute;
            bottom: -2rem;
            left: 0;
            width: 100%;
            height: 2rem;
            background: linear-gradient(rgba(white, 1), rgba(white, 0));
        }
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 2rem 1rem 8rem;
        z-index: 1;
    }

    &__table {
        width: 100%;
        border-collapse: collapse;

        &-wrapper {
            display: flex;
            padding: 2rem 2rem 8rem;
        }

        thead {
            th {
                text-align: left;
                font-size: 1.2rem;
                background-color: $color-white;
                color: $color-grey-7;
                padding: 1rem;
                border-bottom: 2px solid $color-grey-3;
                text-align: center;
                text-transform: uppercase;
                // transform: rotate(-90deg);
                // transform-origin: left bottom;
                // height: 10rem;
                // width: 3rem;
            }
        }

        tbody {
            td {
                font-size: 1rem;
                padding: 1rem 0.2rem;
                text-align: center;
            }

            tr {
                cursor: pointer;
                &:nth-child(2n) {
                    background-color: $color-grey-1;
                }

                &:hover,
                &.is-hover {
                    background-color: $color-primary;
                }
            }
        }
    }

    &__card {
        width: 100%;
        padding: 1rem;
        display: flex;
        cursor: pointer;
        flex: 1 0 320px;
        // min-width: 25rem;
        // max-width: 50%;

        // @media (min-aspect-ratio: 7/3) {
        //     width: 50%;
        //     flex: 0 0 50%;
        // }

        // @media (min-aspect-ratio: 8/3) {
        //     width: 100% * (1/3);
        //     flex: 0 0 100% * (1/3);
        // }

        // @include portrait-mode {
        //     flex: 0 0 50%;
        // }

        &-fake {
            height: 0;
            flex: 1 0 320px;
        }

        &-left {
            flex: 1 0 35%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
        }

        &-right {
            flex: 0 1 65%;
            position: relative;
            padding: 1rem;
            background-color: $color-grey-2;
        }

        &-content {
            // border: 1px solid $color-grey-1;
            // padding: 1rem;
            height: 100%;
            box-shadow: 0 0.2rem 0.6rem 0 $color-grey-3;
            transition: all 0.2s ease-out;
            width: 100%;
            border-radius: 0.3rem;
            display: flex;
            overflow: hidden;
        }

        &-title {
            font-size: 2.4rem;
            color: $color-primary;
            font-weight: bold;
            padding: 0 0 1rem 0;

            &-wrapper {
                display: flex;
                flex: 1 0 50%;
                justify-content: space-between;
                align-items: center;
            }
        }

        &-compare-button {
            width: 4rem;
            height: 4rem;
            @include ff-icon;
            flex: 0 0 4rem;
            // border: 1px solid $color-grey-5;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2rem;
            box-shadow: 0 0.1rem 0.5rem 0 rgba(black, 0.4);
            background-color: $color-white;
            color: $color-black;
            transition: all 0.2s ease-out;
            position: absolute;
            right: 1rem;
            top: 1rem;
            z-index: 2;

            &.is-selected {
                background-color: $color-primary;
                color: $color-white;
            }

            &:hover {
                background-color: $color-highlight;
                color: $color-white;
                box-shadow: 0 0.1rem 0.5rem 0 rgba($color-secondary, 0.7);
            }
        }

        &-availability {
            font-size: 1.6rem;
            color: $color-grey-6;
            font-weight: bold;
            padding: 0 0 1rem 0;
        }

        &-layout {
            width: 100%;
            height: 100%;
            position: relative;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &-split {
            display: flex;
        }

        &-row {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            flex: 0 0 50%;
        }

        &-prop {
            display: flex;
            flex-direction: column;
            // flex: 1 0 50%;
            padding: 0.5rem 0;

            &-label {
                font-size: 1.2rem;
                text-transform: uppercase;
                color: $color-grey-7;
            }

            &-value {
                font-size: 1.6rem;
                font-weight: bold;
                color: $color-grey-7;
            }
        }

        &.is-hover {
            .apartment-list {
                &__card {
                    &-content {
                        box-shadow: 0 0.3rem 1rem 0 $color-primary;
                    }
                }
            }
        }
    }

    &__compare-button-wrapper {
        position: sticky;
        width: 100%;
        bottom: 0;
        padding: 2rem 1rem;
        display: flex;
        z-index: 2;
        justify-content: center;
        align-items: center;
        background: linear-gradient(rgba(white, 0), rgba(white, 1));
    }
}
