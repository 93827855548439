.button {
    background-color: $color-grey-1;
    border: 0 none;
    border-radius: 6rem;
    color: $color-grey-7;
    padding: 1rem 2rem;
    text-transform: uppercase;
    margin: 0 0.5rem;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 500;
    @include ff-text;
    outline: none;
    box-shadow: 0 0 1rem 0 rgba(black, 0.3);
    display: flex;
    align-items: center;
    text-decoration: none;

    &.small {
        text-transform: none;
        font-size: 1.2rem;
    }

    &.icon {
        @include ff-icon;
        padding: 0.5rem;
        font-size: 3rem;
    }

    .icon {
        @include ff-icon;
        font-size: 2rem;
        margin-right: 1rem;
    }

    &.is-active {
        background-color: $color-primary;
        color: $color-white;
        box-shadow: 0 0 1rem 0 $color-primary;

        &:hover {
            background-color: $color-highlight-2;
        }
    }

    &:hover {
        background-color: $color-highlight-2;
        color: $color-white;
    }
}
