@import "../../scss/variables";

.dollHouse {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;

    &.hide {
        display: none;
    }

    &__images {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 0.4s ease-in-out;
        user-select: none;
        z-index: 2;

        img {
            position: absolute;
            left: 0;
            opacity: 0;
            user-select: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__images-container {
        height: 100%;
        width: 100%;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-out;
        position: absolute;
        z-index: 1;
        // touch-action: none;

        @include landscape-mode {
            height: 100%;
            width: 100vh * 4/ 3;
        }

        &.hide {
            opacity: 0;
        }

        @for $i from 0 through 300 {
            &.index-#{$i} {
                .dollHouse__images {
                    .image-#{$i} {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__buttons-bottom {
        display: none;

        @include landscape-mode {
            position: absolute;
            bottom: 0;
            padding: 1rem;
            z-index: 3;
            display: block;
        }
    }
}
